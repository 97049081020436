import { render, staticRenderFns } from "./GiftCode.vue?vue&type=template&id=7860c453&scoped=true&"
import script from "./GiftCode.vue?vue&type=script&lang=js&"
export * from "./GiftCode.vue?vue&type=script&lang=js&"
import style0 from "./GiftCode.vue?vue&type=style&index=0&id=7860c453&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7860c453",
  null
  
)

export default component.exports